import React, { useState, useEffect } from "react";
import Select from "react-select";
import Axios from "utils/Axios";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import coverImageDefault from "../../../assets/images/groove-sign-up.jpg";
import Avatar from "@mui/material/Avatar";
import truncate from "truncate-html";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// react dnd for drag and drop scenario
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "EVENT";

const imageUrl = (url) => {
  return process.env.REACT_APP_BASE_IMAGE_URL + url;
};

const truncateHtml = (htmlContent, length = 100) => {
  return truncate(htmlContent, {
    length: length,
    ellipsis: "...",
    keepImageTag: true,
  });
};

// Draggable Event Component for Display Grid
function DraggableEvent({ event }) {
  const [, drag] = useDrag({
    type: ItemType,
    item: { id: event.id, title: event.title, cityId: event.cityId },
  });

  return (
    <div
      ref={drag}
      key={event.id}
      className="bg-gray-800 text-white p-4 rounded-lg shadow-lg relative cursor-pointer w-full"
    >
      <div className="flex gap-x-8">
        <Avatar
          alt={event.title}
          src={imageUrl(event.image) || coverImageDefault}
          sx={{ width: 56, height: 56 }}
          variant="rounded"
        />
        <div>
          <h2 className="text-lg font-bold">{event.title}</h2>
          <p className="text-xs font-thin text-green-400">{event.location}</p>
        </div>
      </div>
      <div className="mt-10">
        <p
          className="text-sm text-gray-400"
          dangerouslySetInnerHTML={{
            __html: truncateHtml(event.description, 100),
          }}
        ></p>
      </div>
      <div className="mt-5">
        <p className="text-xs text-gray-400 mt-2">
          Event Date: {new Date(event.date).toLocaleString()}
        </p>
      </div>
    </div>
  );
}

// Drop Zone for Top Events with Simple Title Display
function DropZone({ label, acceptDrop, event }) {
  const [{ isOver }, drop] = useDrop({
    accept: ItemType,
    drop: (item) => acceptDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className="w-full sm:w-1/3 h-24 border-2 border-solid rounded-lg flex justify-center items-center p-4"
      style={{ backgroundColor: isOver ? "#6B21A8" : "#1F2937" }}
    >
      {/* If no event is dropped, show a placeholder */}
      {event ? (
        <span className="font-bold">{event.title}</span>
      ) : (
        <div className="flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 text-gray-400 mb-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          <span className="text-gray-400">{label}</span>
        </div>
      )}
    </div>
  );
}

function TopThreeEvents() {
  const [allEvents, setAllEvents] = useState([]);
  const [topEvents, setTopEvents] = useState({ top1: null, top2: null, top3: null });
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [loadedEvents, setLoadedEvents] = useState(12);
  const token = localStorage.getItem("token");

  // Fetch events from the backend
  useEffect(() => {
    Axios.get(`?type=adminEvent&token=${token}`)
      .then((response) => {
        const eventData = response.data;
  
        // Flatten and extract events
        const flattenedEvents = eventData.flatMap((dateGroup) =>
          dateGroup.events?.map((event) => ({
            id: event.id,
            title: event.title,
            description: event.description,
            time: event.time,
            image: event.image,
            location: event.location,
            ticketPrice: event.ticketPrice,
            date: event.eventDate,
            is_active: event.is_active,
            city_code: event.city_code,
            top_event: event.top_event,
            cityId: event.cityId,
          }))
        );
        console.log("All Events: ", flattenedEvents);
  
        // Set all events
        setAllEvents(flattenedEvents);
        setDisplayedEvents(flattenedEvents.slice(0, 12));
  
        // 1. Filter events that have top_event as 1, 2, or 3
        const top3Events = flattenedEvents.filter(event => 
          ["1", "2", "3"].includes(event.top_event)
        );
  
        // 2. Populate topEvents based on available top3Events
        setTopEvents({
          top1: top3Events[0] || null,
          top2: top3Events[1] || null,
          top3: top3Events[2] || null,
        });
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  }, [token]);
  
  // Handle search and filter functionality
  const handleSearch = (query) => {
    setSearchQuery(query);
    filterEvents(query, selectedCity);
  };

  // City filter dropdown handler
const handleCityFilter = (cityCode) => {
  setSelectedCity(cityCode);
  filterEvents(searchQuery, cityCode);
};

  // filtering events by the city
  const filterEvents = (query, cityCode) => {
    let filtered = allEvents;
  
    if (query) {
      filtered = filtered.filter((event) =>
        event.title.toLowerCase().includes(query.toLowerCase())
      );
    }
  
    if (cityCode) {
      filtered = filtered.filter((event) => event.city_code === cityCode);
    }

    setDisplayedEvents(filtered.slice(0, 12));

    const top1Event = filtered.find(event => event.top_event === "1") || null;
    const top2Event = filtered.find(event => event.top_event === "2") || null;
    const top3Event = filtered.find(event => event.top_event === "3") || null;
  
    setTopEvents({
      top1: top1Event,
      top2: top2Event,
      top3: top3Event,
    });
  };

  // handle drop of tghe event
  const handleDrop = (position, event) => {
    setTopEvents((prev) => ({ ...prev, [position]: event }));
  };

  // Load more events when "Load more" is clicked
  const loadMoreEvents = () => {
    setLoadedEvents((prev) => prev + 12);
    setDisplayedEvents(allEvents.slice(0, loadedEvents + 12));
  };

  //hadnle form submission
  const handleFormSumit = () => {
    const payload = [
      { id: topEvents.top1?.id, position: 1, city: topEvents.top1?.cityId },
      { id: topEvents.top2?.id, position: 2, city: topEvents.top2?.cityId },
      { id: topEvents.top3?.id, position: 3, city: topEvents.top3?.cityId },
    ].filter((item) => item.id); //avoid nulls

    console.log("DRAG DROP PAYLOAD: ", payload);

    // Send to API
    Axios.put(`?type=adminEvent&token=${token}`, {
      selectedEvents: payload,
    })
      .then((response) => {
        toast.success("Top Events Updated!");
        // alert("Top Events Updated!")
        // console.log("Success:", response.data);
      })
      .catch((error) => {
        console.error("Failed to submit:", error);
      });
  };

  // Dropdown options for city filter
  const cityOptions = [
    { value: null, label: "East Africa" },
    { value: "KE", label: "Nairobi" },
    { value: "KGL", label: "Kigali" },
    { value: "KMP", label: "Kampala" },
    { value: "TZ", label: "Tanzania" },
    { value: "BJ", label: "Bujumbura" },
    { value: "ZB", label: "Zanzibar" },
    { value: "KIN", label: "Kinshasa" },
  ];

  const theme = useTheme();

  // State to track selected events
  const [selectedEvents, setSelectedEvents] = useState([]);

  return (
    <>
     <ToastContainer
    	position="top-right"
    	/>
      <div className="promoterEvents">
        <div className="AddEvent flex flex-col justify-center items-center w-full h-full text-center pb-0">
            <div className="lg:mx-12 my-2">
              <div className="text-2xl font-bold py-0 text-primary">Top 3 Events</div>
              <div className="text-sm font-light pb-2">
                Flag the Top three Events from all the available events will be listed here.
              </div>
            </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <div className="p-4">
            {/* Search and Filter Bar */}
            <div className="flex flex-wrap justify-center items-center gap-4 p-4">
              <input
                type="text"
                placeholder="Search events..."
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                style={{ color: "#1F2937" }}
                className="p-2 border border-gray-300 rounded-md shadow-sm w-full max-w-md"
              />

              {/* City Filter Dropdown */}
              <Select
                options={cityOptions}
                value={cityOptions.find((opt) => opt.value === selectedCity)}
                onChange={(option) => handleCityFilter(option?.value)}
                placeholder="Filter by city"
                className="w-full max-w-xs"
                styles={{
                  control: (base) => ({
                    ...base,
                    color: "#1F2937",
                    width: "200px",
                  }),
                  menu: (base) => ({
                    ...base,
                    width: "200px",
                  }),
                  option: (base, state) => ({
                    ...base,
                    color: "#1F2937",
                    backgroundColor: state.isFocused ? "#1F2937" : "white",
                    padding: "5px 10px",
                    ":hover": {
                      backgroundColor: "#1F2937",
                      color: "white",
                    },
                  }),
                }}
              />
            </div>

            {/* Drag and Drop for Top 3 Events */}
            <div className="flex justify-center gap-4 p-4">
              <div>Top Event 1</div>
              <div>Top Event 2</div>
              <div>Top Event 3</div>
            </div>
            <div className="flex justify-center gap-4 p-4">
              <DropZone
                label="Top Event 1"
                acceptDrop={(event) => handleDrop("top1", event)}
                event={topEvents.top1}
              />
              <DropZone
                label="Top Event 2"
                acceptDrop={(event) => handleDrop("top2", event)}
                event={topEvents.top2}
              />
              <DropZone
                label="Top Event 3"
                acceptDrop={(event) => handleDrop("top3", event)}
                event={topEvents.top3}
              />
            </div>

            {/* Submit Button for Top Events */}
            <div className="text-center mt-4">
              <button
                onClick={handleFormSumit}
                className="px-4 py-2 bg-[#6B21A8] text-white rounded-md shadow-md"
              >
                Submit
              </button>
            </div>

            {/* Event Display with Load More */}
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6 p-4 w-full">
              {displayedEvents.map((event) => (
                <DraggableEvent key={event.id} event={event} />
              ))}
            </div>

            {/* Load More Button */}
            {displayedEvents.length < allEvents.length && (
              <div className="text-center">
                <button
                  onClick={loadMoreEvents}
                  className="px-4 py-2 bg-[#1F2937] text-white rounded-md shadow-md"
                >
                  Load More...
                </button>
              </div>
            )}
          </div>
        </DndProvider>
      </div>
    </>
  );
}

export default TopThreeEvents;
